import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SliderMovie from './SliderMovie';
import { useHistory } from 'react-router-dom';

const News = () => {
    //Backend
    const [news, setNews] = useState([]);
    const history = useHistory();

    useEffect(() => {
        axios.get("https://api.themoviedb.org/3/movie/now_playing?api_key=7c767d3b131d9b742f3b12a5945c3a74&language=fr-CA&page=1").then((res) => setNews(res.data.results));
    }, []);

    //Front
    return (
        <div className='movieHomeSection'>
            <h1 >Nouveautées</h1>
            <div className='main' id="newMovieId">
                {<div className='sectionMovie'>
                    {news.map((m) => (
                        <SliderMovie movie={m} key={m.id} onClickMovie={() => history.push('/movieDetails', { id: m.id })}/>
                    ))}
                </div>}
            </div>

        </div>
    )
}

export default News;