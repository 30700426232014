import React, { useState, useEffect } from "react";
import axios from "axios";
import Movie from "./Movie";
import TitleHeader from "./TitleHeader";
import { useHistory } from 'react-router-dom';

const CategoriesMovies = (props) => {

    const { idGenre } = props;
    const history = useHistory();
    const [data, setData] = useState([]);
    const [id, setId] = useState();
    const [categories, setCategories] = useState([]);

    const idParam = idGenre ? idGenre : id;

    const [topRated, setTopRated] = useState([]);
    var hidden = false;
        const toggleHide = (e) => {
            e.preventDefault();
            hidden = !hidden;
            let itemToHide = document.getElementsByClassName('switchHide');
            if (!hidden) {
                
                for (var i = 0; i < itemToHide.length; i++) {
                    itemToHide[i].style.display = "none";
                }
            } else {
                for (var i = 0; i < itemToHide.length; i++) {
                    itemToHide[i].style.display = "block";
                }
                
                
            }
           
        }
    
    useEffect(() => {
        axios.get("https://api.themoviedb.org/3/movie/top_rated?api_key=7c767d3b131d9b742f3b12a5945c3a74&language=fr-CA&page=1").then((res) => setTopRated(res.data.results));
    }, []);
    const URL = `https://api.themoviedb.org/3/discover/movie?api_key=a67b57849deb687f2cd49d7a8298b366&language=fr-CA&page=1&with_genres=${idParam}`;
    const URL_GENRES = "https://api.themoviedb.org/3/genre/movie/list?api_key=a67b57849deb687f2cd49d7a8298b366&language=fr-CA";

    useEffect(() => {
        axios.get(URL).then((res) => {
            setData(res.data.results);
        });
        axios.get(URL_GENRES).then((res) => {
            setCategories(res.data.genres);
        });

    }, [idParam]);

    const onClick = (e) => {
        setId(e.target.id);
    }
    const onClickCat = (e) => {
        setId(null);
    }
 
    
    
    return (
        //Categorie section
        <div className="categoriesMovies row">
            <TitleHeader />
            {!idGenre && (
                <div className="sort-container col-2 bigCat">
                    
                    <h4 class="titrecat" onClick={onClickCat}>Catégories</h4>
                    <div className="d-flex flex-column">
                        {categories.map(genre => {
                            return <div className="p-3 divButtonLeft"><button className={genre.id==idParam?
                                                                                        "h1 catactiv" :
                                                                                        "h1"
                            } onClick={onClick} id={genre.id}>{genre.name}</button></div>
                        })}
                    </div>
                    
                </div>
          
                    
            
           
            )}
    
            {!idGenre && (
                <div className="sort-container smallCat">
                    <button className="catButton" onClick={toggleHide}>Catégories</button>
                    <div className="d-flex flex-wrap">
                        {categories.map(genre => {
                            return <div class="p-2 divButtonLeft switchHide"><button onClick={onClick} id={genre.id}>{genre.name} | </button></div>
                        })}
                    </div>
                </div>

            )}
            {/* movie section */}
            {idParam ? (
                <div className='container-fluid col-9 centeredBlock'>
                    {/* {categories.map(genre => {
                        if (genre.id === idGenre) {
                            return <h1 className='slider-title'>{genre.name}</h1>
                        }
                    })} */}
                    <div className="row justify-content-center">
                        {data.map((movie) => (
                            <div className="scrollcards limit">
                                <Movie movie={movie} key={movie.id} onClickMovie={() => history.push('/movieDetails', { id: movie.id })} />
                            </div>
                    ))}
                    </div>
                    </div>
            ) : (
                    <div className="container-fluid col-9 centeredBlock">
                        <h1 className='slider-title'>Mieux notés</h1>
                        <div className="row justify-content-center">
                                {topRated.map((movie) => (
                                    <div className="scrollcards limit">
                                        <Movie movie={movie} key={movie.id} onClickMovie={() => history.push('/movieDetails', { id: movie.id })} />
                                    </div>
                                ))}
                        </div>
                        
                </div>
            )}
        </div>
    )
    
    }


export default CategoriesMovies;