import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SliderMovie from './SliderMovie';
import { useHistory } from 'react-router-dom';


const PopularMovies = () => {
    //Backend
    const [popular, setPopular] = useState([]);
    const history = useHistory();
    useEffect(() => {
        axios.get("https://api.themoviedb.org/3/movie/popular?api_key=a67b57849deb687f2cd49d7a8298b366&language=fr-CA&page=1").then((res) => setPopular(res.data.results));
    }, []);

    //Front
    return (
        <div className='movieHomeSection'>
            <h1>La selection pop-corn du moment</h1>
        <div className='main' id="popularMovieId">
                {<div className='sectionMovie'>
                    {popular.map((m) => (
                        <SliderMovie movie={m} key={m.id} onClickMovie={() => history.push('/movieDetails/?id='+m.id, { id: m.id })} />
                    ))}
                </div>}

        </div>
    </div>
    )
}

export default PopularMovies;