import React from 'react';
import Logo from './Logo';

const Footer = () => {
    return (
        <div className='d-flex justify-content-around footer'>
                <div className=''>
                    <img src="../images/logo.png" alt="logo" />
                </div>
            <div className=''>
                <p>
                    <a className='text-center' href="https://www.pop-shooter.louis-brunon.ca">Air de détente</a>
                </p>
                    <p className='slogan'>Du Cinéma a engloutir comme du pop-corn</p>
                    <p>Tous droits réservés © Pop-Ciné</p>
                </div>
                <div className=''>
                    <img src="../images/popbox.png" alt="boite de pop-corn" />
                </div>
        </div>
    )
}

export default Footer;