import React from 'react';

const SliderMovie = (props) => {

    const { movie, onClickMovie } = props;
    const popMovieId = document.querySelector("#popularMovieId");
    popMovieId.addEventListener("wheel", (evt) => {

        evt.preventDefault();
        popMovieId.scrollLeft += evt.deltaY;
    });
    const toComeMovieId = document.querySelector("#toComeMovieId");
    toComeMovieId.addEventListener("wheel", (evt) => {

        evt.preventDefault();
        toComeMovieId.scrollLeft += evt.deltaY;
    });
    const topRatedMovieId = document.querySelector("#topRatedMovieId");
        topRatedMovieId.addEventListener("wheel", (evt) => {

        evt.preventDefault();
        topRatedMovieId.scrollLeft += evt.deltaY;
        });
    const newMovieId = document.querySelector("#newMovieId");
    newMovieId.addEventListener("wheel", (evt) => {

        evt.preventDefault();
        newMovieId.scrollLeft += evt.deltaY;
    });
   
    return (
        <div className='sliderMovieCard' onClick={onClickMovie}>
            <img className="card-img-top"
                // src={`https://image.tmdb.org/t/p/original${movie.poster_path}`}
                src={
                    movie.poster_path ?
                        `https://image.tmdb.org/t/p/original${movie.poster_path}` :
                        "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png"
                } alt="poster" />
            <div className='card-block'>
                {/* <h4 className='card-title'>{movie.title}</h4> */}
            </div>
        </div>
    )
}

export default SliderMovie;

