import React, { useState, useEffect } from "react";
import axios from "axios";
import Movie from "./Movie";
import { useHistory } from 'react-router-dom';

const ActorCredit = (props) => {


    const { idCredit } = props;
    const [data, setData] = useState([]);
    const history = useHistory();
    const URL = `https://api.themoviedb.org/3/person/${idCredit}/movie_credits?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US`;
    
    useEffect(() => {
        axios.get(`https://api.themoviedb.org/3/person/${idCredit}/movie_credits?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US`).then((res) => setData(res.data.cast));
    }, []);

    data.sort((a, b) => (a.release_date < b.release_date) ? 1 : -1);

    return (
        
        <div className="filmographieTable">
            <div className="d-flex bd-highlight mb-3 headerFilmo">
                <div className="p-2 bd-highlight years-title">Année</div>
                <div className="p-2 bd-highlight">Titre</div>
                <div className="ms-auto p-2 bd-highlight">Rôle</div>
            </div>
            <div className="contentFilmo">
                {data.map((movie) => (
                <div onClick={() => history.push('/movieDetails', { id: movie.id })} className="d-flex bd-highlight mb-3 content-filmo-line">
                        <div className="p-2 bd-highlight years-title">{movie.release_date}</div>
                        <div className="p-2 bd-highlight">{movie.original_title}</div>
                        <div className="ms-auto p-2 bd-highlight">{movie.character}</div>
                    </div>
                ))}
            
            </div>
        
        
            
   
        
        </div>
        
    )

}
export default ActorCredit;