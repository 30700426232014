import React from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import PopularMovies from "../components/PopularMovies";
import ToCome from "../components/ToCome";
import News from "../components/News";
import TopRated from "../components/TopRated";
import TitleHeader from "../components/TitleHeader";

const Popular = () => {
    return (
        <div className="accueil">
            <Navigation />
            <TitleHeader />
            <PopularMovies />
            <div className='movieHomeSection'>
                <h1>À venir</h1>
                <ToCome />
            </div>
            <News />
            <TopRated />
            <Footer />
        </div>
    )
}

export default Popular;