import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SliderMovie from './SliderMovie';
import { useHistory } from 'react-router-dom';

const ToCome = () => {
    //Backend
    const [toCome, setToCome] = useState([]);
    const history = useHistory();
   

    useEffect(() => {
        axios.get("https://api.themoviedb.org/3/movie/upcoming?api_key=7c767d3b131d9b742f3b12a5945c3a74&language=fr-CA&page=1").then((res) => setToCome(res.data.results));
    }, []);
    
    //Front
    return (
        
        <div className='main' id="toComeMovieId">
            {<div className='sectionMovie'>
                    {toCome.map((m) => (
                        <SliderMovie movie={m} key={m.id}onClickMovie={() => history.push('/movieDetails', { id: m.id })} />
                    ))}
                </div>}
            </div>
    
    )
}

export default ToCome;