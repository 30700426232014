
import React, { useState, useEffect } from 'react';

const TitleHeader = () => {

    //Front
    return (
        <h1 className="slogan-title">Du Cinéma à engloutir comme du pop-corn</h1>
    )
}

export default TitleHeader;