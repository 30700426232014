import React from "react";

const Actor = (props) => {

    const { actor, onClickActor } = props;

    return (
        <div className="card" onClick={onClickActor}>
            <img className="card-img-top"
                src={
                    actor.profile_path ?
                        `https://image.tmdb.org/t/p/original${actor.profile_path}` :
                        "../images/popconito.png"
                }
                alt="profile"
            />
            <div className="card-block">
                <div className="actorNameContainers">
                    <h1 className="actorName">{actor.original_name}</h1>
                </div>
            </div>
        </div>
    )
}

export default Actor;