import React from "react";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import TitleHeader from "../components/TitleHeader";


const About = () => {

    // var x = document.getElementsByClassName('about-card-container');
    // var width = (window.innerWidth > 0) ? window.innerWidth : screen.Width;
    // console.log(width);
    // if (x.length > 0) {
    //     if (width <= 767) {
    //         x[0].className = 'flex-column';
    //     }
    // }
    return(
        <div className="about-container">
            <Navigation />
            <div className="d-flex justify-content-center">
                <div className="pop-about-title">
                    <img src="../images/popcorn.png"  alt="search button"  />
                </div>
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
            </div>
            <h1>Notre Pop - Équipe</h1>
            <div className="d-flex justify-content-center pop-about-line-bottom">
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
                <div className="pop-about-title">
                    <img src="../images/popcorn.png" alt="search button" />
                </div>
            </div>
            <div className="about-card-container d-flex   justify-content-center">
                <div className="about-card left-about-card">
                    <img src="../images/laurence.png" alt="search button" />
                    <h2>Laurence Boucand</h2>
                    <p>Design</p>
                    <p>Intégration</p>
                    <p className="card-last-p">Friande de pop-corn salé</p>
                </div>
                <div className="about-card right-about-card">
                    <img src="../images/louis.png" alt="search button" />
                    <h2>Louis Brunon</h2>
                    <p>Intégration</p>
                    <p>Programation</p>
                    <p className="card-last-p">Amateur de pop-corn sucré</p>
                </div>
            </div>
            <div className="about-text-container">
                <p>Nous sommes le diabolique duo qui avons conçu le site pop ciné !</p>
                <p>Tout les deux étudiants en AEC Technique de développement web, nous présentons ici un projet de fin de session. Ce projet regroupe les frandes lifnes de la formation : Designer, Intégration et programmation. Le but de ce TP était de présenter un site dynamique en utilisant une API sur le thème du cinéma. Les logiciel et technologie imposés étaient : Adobe XD, HTML, CSS et React.</p>
                <p>On a voulu mettre mos utilisateurs cinéphiles dans une ambiance de vieux cinéma, un paquet de pop-corn en main prêt à choisir son film.</p>
            </div>




            <Footer />
        </div>
    )
}

export default About;