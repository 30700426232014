import React from 'react';

const Movie = (props) => {

    const { movie, onClickMovie } = props;

    return (
        <div className='card' onClick={onClickMovie}>
        
            <img className="card-img-top align-bottom"
                // src={`https://image.tmdb.org/t/p/original${movie.poster_path}`}
                src={
                    movie.poster_path ?
                        `https://image.tmdb.org/t/p/original${movie.poster_path}` :
                        "../images/popconito.png"
                } alt="poster" />
            <div className='card-block'>
                { !movie.poster_path ?
                <h4 className='card-title mamarg'>{movie.title}</h4>:
                <div></div>
             }
            </div>
        </div>
    )
}

export default Movie;

