import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Movie from '../components/Movie';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import TitleHeader from '../components/TitleHeader';
import { useHistory } from 'react-router-dom';

const Search = (props) => {

    const isQuery = props.location.state.searchInput;
   
    const history = useHistory();
    const [data, setData] = useState([]);
    const [query, setQuery] = useState("");
    const [menuSearch, setMenuSearch] = useState("");
    const URL = `https://api.themoviedb.org/3/search/movie?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US&query=${query}`;
    const URL_MENU = `https://api.themoviedb.org/3/search/movie?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US&query=${isQuery}`;
    const idParam = "";
    useEffect(() => {
        axios.get(URL).then((res) => {
            setData(res.data.results)
        });
    }, [query]);
    useEffect(() => {
        axios.get(URL_MENU).then((res) => {
            setData(res.data.results)
        });
    }, [menuSearch]);
    const onSearch = (event) => {
        setQuery(event.target.value);
    }

    return (
        <div className='searchMovie'>
            <Navigation />
            <TitleHeader />
            <div className='search-movie-header'>
                <h1>Qu'est-ce qu'on regarde ?</h1>
                <div className='input-section d-flex justify-content-center'>
                    <div>
                        <input
                            className='input'
                            placeholder='Recherche ...'
                            onChange={onSearch}
                        ></input>
                    </div>
                    <div className='searchIcon'>
                        <img src="./images/searchImg.png" alt="search button" />
                    </div>
                </div>
            </div>
            {query ? (
                <div className="row justify-content-center">
                    {data.map((movie) => (
                        <div className="scrollcards limit">
                            <Movie movie={movie} key={movie.id} onClickMovie={() => history.push('/movieDetails', { id: movie.id })} />
                        </div>
                    ))}
                </div>
            ) : isQuery ? (
                    <div className="row justify-content-center">
                        {data.map((movie) => (
                            <div className="scrollcards limit">
                                <Movie movie={movie} key={movie.id} onClickMovie={() => history.push('/movieDetails', { id: movie.id })} />
                            </div>
                        ))}
                    </div>
            ) :
                    <div className='nosearch'>
                        <h4>Veuillez entrer votre recherche</h4>
                    </div>
            }
            <Footer />
        </div>
    )
}



export default Search;