import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Logo from './Logo';
import { useHistory } from 'react-router-dom';

const Navigation = () => {

    var hiddenMenu = false;
    const toggleHide = (e) => {
        e.preventDefault();
        hiddenMenu = !hiddenMenu;
        let itemToHide = document.getElementsByClassName('menu-to-hide');
        if (!hiddenMenu) {
            for (var i = 0; i < itemToHide.length; i++) {
                itemToHide[i].style.display = "none";
            }
        } else {
            for (var i = 0; i < itemToHide.length; i++) {
                itemToHide[i].style.display = "block";
            }
        }
    }
    var mediaScreenSize = window.matchMedia("(max-width: 820px)");
    var navToHide = document.getElementById('menuToHideId');
    mediaScreenSize.addListener(screenListener);
    window.onload = function () {
        screenListener(mediaScreenSize);
    };  
    
    function screenListener(mediaScreenSize) {
        if (!mediaScreenSize.matches) {
            navToHide.style.display = "none";
        }
    }
    // var searchInputKeyboard = document.getElementById("searchMenuId");

    // // Execute a function when the user presses a key on the keyboard
    // searchInputKeyboard.addEventListener("keypress", function (event) {
    //     // If the user presses the "Enter" key on the keyboard
    //     if (event.key === "Enter") {
    //         // Cancel the default action, if needed
    //         event.preventDefault();
    //         // Trigger the button element with a click
    //         onClickSearch();
    //     }
    // });

    // --------------------------------------------------
    function pop(e) {
        let amount = 30;
        switch (e.target.dataset.type) {
            case 'shadow':
            case 'line':
                amount = 60;
                break;
        }
        // Quick check if user clicked the button using a keyboard
        if (e.clientX === 0 && e.clientY === 0) {
            const bbox = e.target.getBoundingClientRect();
            const x = bbox.left + bbox.width / 2;
            const y = bbox.top + bbox.height / 2;
            for (let i = 0; i < 30; i++) {
                // We call the function createParticle 30 times
                // We pass the coordinates of the button for x & y values
                createParticle(x, y, e.target.dataset.type);
            }
        } else {
            for (let i = 0; i < amount; i++) {
                createParticle(e.clientX, e.clientY + window.scrollY, e.target.dataset.type);
            }
        }
    }
    function createParticle(x, y, type) {
        const particle = document.createElement('particle');
        document.body.appendChild(particle);
        let width = Math.floor(Math.random() * 30 + 8);
        let height = width;
        let destinationX = (Math.random() - 0.5) * 300;
        let destinationY = (Math.random() - 0.5) * 300;
        let rotation = Math.random() * 520;
        let delay = Math.random() * 200;

        
        particle.style.backgroundImage = '../images/popcorn.png';
            

        particle.style.width = `${width}px`;
        particle.style.height = `${height}px`;
        const animation = particle.animate([
            {
                transform: `translate(-50%, -50%) translate(${x}px, ${y}px) rotate(0deg)`,
                opacity: 1
            },
            {
                transform: `translate(-50%, -50%) translate(${x + destinationX}px, ${y + destinationY}px) rotate(${rotation}deg)`,
                opacity: 0
            }
        ], {
            duration: Math.random() * 1000 + 5000,
            easing: 'cubic-bezier(0, .9, .57, 1)',
            delay: delay
        });
        animation.onfinish = removeParticle;
    }
    function removeParticle(e) {
        e.srcElement.effect.target.remove();
    }

    if (document.body.animate) {
        document.querySelectorAll('button').forEach(button => button.addEventListener('click', pop));
    }
    const [searchMenuInput, setSearchMenuInput] = useState('');
    const history = useHistory();
    function onClickSearch() {
        history.push('/search', { searchInput: searchMenuInput })
    
    }
// -----------------------------------------------------------    
return (
        <div className='navigation'>
            <div className='navigation-large'>
                <div className='d-flex justify-content-around'>
                <div className=''>
                    <Logo />
                </div>
                <div className='d-flex justify-content-evenly navlink'>
                    <div className=''>
                        <NavLink exact to="/" activeClassName='nav-active'>
                            Accueil
                        </NavLink>
                    </div>
                        <div className='tospace'>
                        <NavLink exact to="/categories" activeClassName='nav-active'>
                            Catégories
                        </NavLink>
                    </div>
                    <div>
                        <NavLink exact to="/about" activeClassName='nav-active'>
                        À propos
                        </NavLink>
                    </div>
                </div>
                <div className='d-flex justify-content-end'>
                    <div className='inputMenu'>
                        <input placeholder='Recherche ...' id="searchMenuId" value={searchMenuInput} name="searchMenuInput" onChange={e => setSearchMenuInput(e.target.value)} />
                    </div>
                    <div className='flex-shrink-0' onClick={onClickSearch}>
                        <img className='loupe' src="../images/loupe.png" alt="search button" />
                    </div>
                </div>
               </div>
            </div>
            <div className='navigation-small'>
                <div className='menu-small d-flex justify-content-between'>
                    <div className='d-flex'>
                        <button className="menu-small-button" onClick={toggleHide}>
                            <img className='menu-small-icon' src="../images/bars-solid.png" alt="search button" />
                        </button>
                        
                    </div>
                    <div className='d-flex'>
                        <img className='menu-small-logo' src="../images/logo.png" alt="search button" />
                    </div>
                    <div className='d-flex'>
                    <div className='inputMenu'>
                        <input placeholder='Recherche ...' id="searchMenuLittleId" value={searchMenuInput} name="searchMenuInput" onChange={e => setSearchMenuInput(e.target.value)} />
                    </div>
                    <div className='flex-shrink-0' onClick={onClickSearch}>
                        <img src="../images/loupe.png" className='menu-small-logo menu-small-icon-search' alt="search button" />
                    </div>
                    </div>
                </div>
            </div>
            <div className='menu-to-hide' id='menuToHideId'>
                <div className='d-flex flex-column bd-highlight mb-3'>
                    <div className='p-2 bd-highlight'>
                        <NavLink exact to="/" activeClassName='nav-active'>
                            Accueil
                        </NavLink>
                    </div>
                    <div className='p-2 bd-highlight'>
                        <NavLink exact to="/categories" activeClassName='nav-active tospace'>
                            Catégorie
                        </NavLink>
                    </div>
                    <div className='p-2 bd-highlight'>
                        <NavLink exact to="/search" activeClassName='nav-active'>
                            A propos
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default Navigation;