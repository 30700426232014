import React, { useState, useEffect } from "react";
import axios from "axios";
import Navigation from "../components/Navigation";
import ActorCredit from "../components/ActorCredit";
import ActorPopularCredit from "../components/ActorPopularCredit";
import Footer from "../components/Footer";
import { useHistory } from "react-router-dom";


const ActorDetails = (props) => {

    const id = props.location.state.id;
    const [detail, setDetail] = useState({});
    const [us, setUs] = useState({});
    const [credit, setCredits] = useState({});
    const URL = `https://api.themoviedb.org/3/person/${id}?api_key=a67b57849deb687f2cd49d7a8298b366&language=fr`;
    const URL_US = `https://api.themoviedb.org/3/person/${id}?api_key=a67b57849deb687f2cd49d7a8298b366&language=en`;
    // const URL_POPULAR = `https://api.themoviedb.org/3/person/${id}/movie_credits?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US`;
    useEffect(() => {
        axios.get(URL).then((res) => {
            setDetail(res.data);
        });
    }, [id]);
    useEffect(() => {
        axios.get(URL_US).then((res) => {
            setUs(res.data);
        });
    }, [id]);
    // useEffect(() => {
    //     axios.get(URL_POPULAR).then((res) => {
    //         setCredits(res.data);
    //     });
    // }, [id]);
    
    const calculateAge = (birthday) => {
        const ageDifMs = Date.now() - new Date(birthday).getTime();
        const ageDate = new Date(ageDifMs);
        let age = Math.abs(ageDate.getUTCFullYear() - 1970);
        return age;
    }
    let ageOrDeathDate = "";
    function ageOrDead(_deathday) {
        if (_deathday != null) {
            ageOrDeathDate = detail.deathday;
            return "Date du décès ";
        } else {
            ageOrDeathDate = calculateAge(detail.birthday);
            ageOrDeathDate += "ans";
            return "Age "; 
        } 
    }
    return (
        <React.Fragment>
            <Navigation />
            <div className="details-actors-container">
                <div className="movie_header d-flex">
                    <div className="imgContainer actorImg">
                            <img
                                className=""
                                src={
                                    detail.profile_path ?
                                        `https://image.tmdb.org/t/p/original${detail.profile_path}` :
                                        "../images/popconito.png"
                                }
                                alt="poster"
                            />
                    </div>
                    <div className="actorsContent">
                        <h1>{detail.name}</h1>
                        <p className="movieDetailsInfos"><span className="actorYellowText">Métier</span>  {detail.known_for_department}</p>
                        <p className="movieDetailsInfos"><span className="actorYellowText">Date de Naissance </span>{detail.birthday}</p>
                        <p className="movieDetailsInfos"><span className="actorYellowText">{ageOrDead(detail.deathday)}</span>{ageOrDeathDate}</p>
                        <p className="movieDetailsInfos"><span className="actorYellowText">Lieu de Naissance</span> {detail.place_of_birth}</p>
                        <div className="noteSection">
                            <div className="d-flex bd-highlight mb-3">
                                <div className="p-2 bd-highlight"><img className="noteImg" src="../images/popbox.png" /></div>
                                <div className="p-2 bd-highlight"><p><span className="actorYellowText">{Math.round(detail.popularity)}%</span></p></div>
                            </div>
                        </div> 
                    </div> 
                </div>
                <div className="movie_desc">
                    <h2>Biographie</h2>
                    <p className="text">
                        {detail.biography ? 
                            detail.biography :
                            us.biography ? 
                                us.biography :
                                "Pas de biographie disponible"
                            }
                    </p>
                </div>
                <div className='container-fluid'>
                    <h1 className='slider-title'>Les mieux notés</h1>
                    <ActorPopularCredit idCredit={id}/>
                </div>
                <div className="credit-list-container">
                    <h3 className="filmoTitle">Filmographie</h3>
                    <ActorCredit idCredit={id} />
                </div>
            </div>
            <div className="footer-container">
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default ActorDetails;