import React, { useState, useEffect } from "react";
import axios from "axios";
import Movie from "./Movie";
import { useHistory } from 'react-router-dom';

const ActorPopularCredit = (props) => {


    const { idCredit } = props;
    const [data, setData] = useState([]);
    const history = useHistory();
    const URL = `https://api.themoviedb.org/3/person/${idCredit}/movie_credits?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US`;

    useEffect(() => {
        axios.get(`https://api.themoviedb.org/3/person/${idCredit}/movie_credits?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US`).then((res) => setData(res.data.cast));
    }, []);

    data.sort((a, b) => (a.vote_average < b.vote_average) ? 1 : -1);

    return (

        <div className="row ">
            <div className="scrollcards">
                {data.map((movie) => (

                    <Movie movie={movie} key={movie.id} onClickMovie={() => history.push('/movieDetails', { id: movie.id })} />

                ))}
            </div>
        </div>

    )

}
export default ActorPopularCredit;