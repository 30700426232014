import React, { useState, useEffect } from "react";
import axios from "axios";
import Navigation from "../components/Navigation";
import Footer from "../components/Footer";
import Actor from "../components/Actor";
import { useHistory } from "react-router-dom";

const MovieDetails = (props) => {

    const id = props.location.state.id;
    const URL = `https://api.themoviedb.org/3/movie/${id}?api_key=a67b57849deb687f2cd49d7a8298b366&language=fr-CA`;
    const URL_US = `https://api.themoviedb.org/3/movie/${id}?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US`;
    const URL_CREDITS = `https://api.themoviedb.org/3/movie/${id}/credits?api_key=a67b57849deb687f2cd49d7a8298b366&language=en-US`;
    const URL_VIDEO = `https://api.themoviedb.org/3/movie/${id}/videos?api_key=a67b57849deb687f2cd49d7a8298b366&language=fr`;
    const [video, setVideo] = useState({});
    const [detail, setDetail] = useState({});
    const [genres, setGenres] = useState([]);
    const [credits, setCredits] = useState([]);
    const [us, setUs] = useState([]);
    const [categories, setCategories] = useState([]);
    const history = useHistory();
    const genresMovie = "";
    function timeConvert(n) {
        var num = n;
        var hours = (num / 60);
        var rhours = Math.floor(hours);
        var minutes = (hours - rhours) * 60;
        var rminutes = Math.round(minutes);
        return rhours + "h" + rminutes + "min";
    }

    useEffect(() => {
        axios.get(URL).then((res) => {
            setDetail(res.data);
            setGenres(res.data.genres);
        });
        axios.get(URL_CREDITS).then((res) => {
            setCredits(res.data.cast);
        });
    }, [URL, URL_CREDITS]);
    

        useEffect(() => {

            axios.get(URL_US).then((res) => setUs(res.data));

        }, []);
        
        useEffect(() => {

            axios.get(URL_VIDEO).then((res) => setVideo(res.data.results[0].key));

        }, []);
        const VideoUrl = "https://www.youtube.com/watch?v="+video;
   


    const time = timeConvert(detail.runtime);
    console.log(genres[0])
    // Array.prototype.forEach.call(genres, function (g) { 
    //     genresMovie += "&with_genres=";
    //     genresMovie+= g.id;
    //  });
    // genres.forEach(g => {
    //     genresMovie += "&with_genres=";
    //     // genresMovie+= g.id;
    // });

    useEffect(() => {
        axios.get(`https://api.themoviedb.org/3/discover/movie?api_key=a67b57849deb687f2cd49d7a8298b366&language=fr-CA&page=1&with_genres=${genresMovie}`).then((res) => setCategories(res.data.results));
    }, []);


    return (
        <React.Fragment>
            <Navigation />
            <div className="details-container">
                <div className="movie_header d-flex">
                    <div className="imgContainer">
                        <img
                            className=""
                            src={detail.poster_path ?
                                `https://image.tmdb.org/t/p/original${detail.poster_path}`:
                                `../images/popconito.png`
                                
                            }
                            alt="poster"
                        />
                    </div>
                    <div className="movieContent">
                        <h1>{detail.title}</h1>
                        <h2>{detail.tagline}</h2>
                        <p>Titre original : <span className="lightYellow">{detail.original_title}</span></p>
                        <div className="d-flex flex-nowrap">
                            <p className="movieDetailsInfos">{detail.release_date}</p>
                            <p className="movieDetailsInfos">{time}</p>
                        </div>
                        <div className="d-flex flex-nowrap">
                            {genres.map((g) => {
                                return (
                                    <div
                                        key={g.id}
                                        className="type genreDetails"
                                        onClick={() => history.push('/categories', { id: g.id })}>
                                        {g.name}
                                    </div>
                                );
                            })}
                        </div>
                        <div className="noteSection">
                            <h3>Note des utilisateurs :</h3>
                            <div className="d-flex bd-highlight mb-3">
                                <div className="p-2 bd-highlight"><img className="noteImg" src="../images/popbox.png" /></div>
                                <div className="p-2 bd-highlight"><p>{Math.round(detail.vote_average * 10)}%</p></div>
                                <div className="ms-auto p-2 bd-highlight"><div className="trailerButton"><a href={VideoUrl} target="_blank">Bande-Annonce</a></div></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="overviewContainer">
                    <h1>Synopsis</h1>
                    <div>
                        <p className="text">

                            {detail.overview ?
                                detail.overview :
                                us.overview ?
                                    us.overview :
                                    "Pas de synopsis disponible"
                            }
                        </p>
                    </div>
                </div>
                <div className="actorsContainer">

                    <div className='container-fluid'>
                        <div className="slider-title"><h1 className="textActor">Distribution</h1></div>
                        <div className="row">
                            <div className='scrollcards'>
                                {
                                    credits
                                        .slice(0, 6)
                                        .map(credit => {
                                            return (
                                                <Actor
                                                    actor={credit}
                                                    key={credit.id}
                                                    onClickActor={() => history.push("/actorDetails/?id="+credit.id, { id: credit.id })} />
                                            );
                                        })
                                }
                            </div>
                        </div>
                    </div>
                </div>



            </div>
            <div className="footer-container">
                <Footer />
            </div>
        </React.Fragment>
    );
};

export default MovieDetails;